var render = function () {
  var _vm$analytics$electri, _vm$analytics$electri2, _vm$analytics$electri3, _vm$analytics$electri4, _vm$analytics$electri5, _vm$analytics$electri6, _vm$analytics$electri7, _vm$analytics$electri8, _vm$analytics$gasCon, _vm$analytics$gasCon$, _vm$analytics$gasCon2, _vm$analytics$gasCon3, _vm$analytics$gasCon4, _vm$analytics$gasCon5, _vm$analytics$gasCon6, _vm$analytics$gasCon7, _vm$analytics$waterC, _vm$analytics$waterC$, _vm$analytics$waterC2, _vm$analytics$waterC3, _vm$analytics$waterC4, _vm$analytics$waterC5, _vm$analytics$waterC6, _vm$analytics$waterC7, _vm$analytics$wasteC, _vm$analytics$wasteC$, _vm$analytics$wasteC2, _vm$analytics$wasteC3, _vm$analytics$wasteC4, _vm$analytics$wasteC5, _vm$analytics$wasteC6, _vm$analytics$wasteC7, _vm$analytics$pvCons, _vm$analytics$pvCons$, _vm$analytics$pvCons2, _vm$analytics$pvCons3, _vm$analytics$pvCons4, _vm$analytics$pvCons5, _vm$analytics$pvCons6, _vm$analytics$pvCons7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.analyticsOptions.selectedId ? _c('div', [_vm.loadingAction.getAccountTypes ? _c('SpinnerLogo') : _c('div', {
    staticClass: "row mb-4"
  }, [_vm.accountTypes.includes('electricity') ? _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$analytics$electri = _vm.analytics['electricity-consumption']) === null || _vm$analytics$electri === void 0 ? void 0 : (_vm$analytics$electri2 = _vm$analytics$electri.results) === null || _vm$analytics$electri2 === void 0 ? void 0 : _vm$analytics$electri2.netTotalCost,
      "prev-value": (_vm$analytics$electri3 = _vm.analytics['electricity-consumption-prev']) === null || _vm$analytics$electri3 === void 0 ? void 0 : (_vm$analytics$electri4 = _vm$analytics$electri3.results) === null || _vm$analytics$electri4 === void 0 ? void 0 : _vm$analytics$electri4.netTotalCost,
      "precision": "2",
      "icon-class": "fa-plug text-warning",
      "description": "Electricity",
      "units": "GBP",
      "prefix": "£ ",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['electricity-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['electricity-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['electricity-consumption'],
      "estimated-percentage": ((_vm$analytics$electri5 = _vm.analytics['electricity-consumption']) === null || _vm$analytics$electri5 === void 0 ? void 0 : (_vm$analytics$electri6 = _vm$analytics$electri5.results) === null || _vm$analytics$electri6 === void 0 ? void 0 : _vm$analytics$electri6.estimatedNetTotalCost) * 100 / ((_vm$analytics$electri7 = _vm.analytics['electricity-consumption']) === null || _vm$analytics$electri7 === void 0 ? void 0 : (_vm$analytics$electri8 = _vm$analytics$electri7.results) === null || _vm$analytics$electri8 === void 0 ? void 0 : _vm$analytics$electri8.netTotalCost)
    }
  })], 1) : _vm._e(), _vm.accountTypes.includes('gas') ? _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$analytics$gasCon = _vm.analytics['gas-consumption']) === null || _vm$analytics$gasCon === void 0 ? void 0 : (_vm$analytics$gasCon$ = _vm$analytics$gasCon.results) === null || _vm$analytics$gasCon$ === void 0 ? void 0 : _vm$analytics$gasCon$.netTotalCost,
      "prev-value": (_vm$analytics$gasCon2 = _vm.analytics['gas-consumption-prev']) === null || _vm$analytics$gasCon2 === void 0 ? void 0 : (_vm$analytics$gasCon3 = _vm$analytics$gasCon2.results) === null || _vm$analytics$gasCon3 === void 0 ? void 0 : _vm$analytics$gasCon3.netTotalCost,
      "precision": "2",
      "icon-class": "fa-flame text-danger",
      "description": "Gas",
      "units": "GBP",
      "prefix": "£ ",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['gas-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['gas-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['gas-consumption'],
      "estimated-percentage": ((_vm$analytics$gasCon4 = _vm.analytics['gas-consumption']) === null || _vm$analytics$gasCon4 === void 0 ? void 0 : (_vm$analytics$gasCon5 = _vm$analytics$gasCon4.results) === null || _vm$analytics$gasCon5 === void 0 ? void 0 : _vm$analytics$gasCon5.estimatedNetTotalCost) * 100 / ((_vm$analytics$gasCon6 = _vm.analytics['gas-consumption']) === null || _vm$analytics$gasCon6 === void 0 ? void 0 : (_vm$analytics$gasCon7 = _vm$analytics$gasCon6.results) === null || _vm$analytics$gasCon7 === void 0 ? void 0 : _vm$analytics$gasCon7.netTotalCost)
    }
  })], 1) : _vm._e(), _vm.accountTypes.includes('water') ? _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$analytics$waterC = _vm.analytics['water-consumption']) === null || _vm$analytics$waterC === void 0 ? void 0 : (_vm$analytics$waterC$ = _vm$analytics$waterC.results) === null || _vm$analytics$waterC$ === void 0 ? void 0 : _vm$analytics$waterC$.netTotalCost,
      "prev-value": (_vm$analytics$waterC2 = _vm.analytics['water-consumption-prev']) === null || _vm$analytics$waterC2 === void 0 ? void 0 : (_vm$analytics$waterC3 = _vm$analytics$waterC2.results) === null || _vm$analytics$waterC3 === void 0 ? void 0 : _vm$analytics$waterC3.netTotalCost,
      "precision": "2",
      "icon-class": "fa-water text-info",
      "description": "Water",
      "units": "GBP",
      "prefix": "£ ",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['water-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['water-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['water-consumption'],
      "estimated-percentage": ((_vm$analytics$waterC4 = _vm.analytics['water-consumption']) === null || _vm$analytics$waterC4 === void 0 ? void 0 : (_vm$analytics$waterC5 = _vm$analytics$waterC4.results) === null || _vm$analytics$waterC5 === void 0 ? void 0 : _vm$analytics$waterC5.estimatedNetTotalCost) * 100 / ((_vm$analytics$waterC6 = _vm.analytics['water-consumption']) === null || _vm$analytics$waterC6 === void 0 ? void 0 : (_vm$analytics$waterC7 = _vm$analytics$waterC6.results) === null || _vm$analytics$waterC7 === void 0 ? void 0 : _vm$analytics$waterC7.netTotalCost)
    }
  })], 1) : _vm._e(), _vm.accountTypes.includes('waste') ? _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$analytics$wasteC = _vm.analytics['waste-consumption']) === null || _vm$analytics$wasteC === void 0 ? void 0 : (_vm$analytics$wasteC$ = _vm$analytics$wasteC.results) === null || _vm$analytics$wasteC$ === void 0 ? void 0 : _vm$analytics$wasteC$.netTotalCost,
      "prev-value": (_vm$analytics$wasteC2 = _vm.analytics['waste-consumption-prev']) === null || _vm$analytics$wasteC2 === void 0 ? void 0 : (_vm$analytics$wasteC3 = _vm$analytics$wasteC2.results) === null || _vm$analytics$wasteC3 === void 0 ? void 0 : _vm$analytics$wasteC3.netTotalCost,
      "precision": "2",
      "icon-class": "fa-waste",
      "description": "Waste",
      "units": "GBP",
      "prefix": "£ ",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['waste-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['waste-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['waste-consumption'],
      "estimated-percentage": ((_vm$analytics$wasteC4 = _vm.analytics['waste-consumption']) === null || _vm$analytics$wasteC4 === void 0 ? void 0 : (_vm$analytics$wasteC5 = _vm$analytics$wasteC4.results) === null || _vm$analytics$wasteC5 === void 0 ? void 0 : _vm$analytics$wasteC5.estimatedNetTotalCost) * 100 / ((_vm$analytics$wasteC6 = _vm.analytics['waste-consumption']) === null || _vm$analytics$wasteC6 === void 0 ? void 0 : (_vm$analytics$wasteC7 = _vm$analytics$wasteC6.results) === null || _vm$analytics$wasteC7 === void 0 ? void 0 : _vm$analytics$wasteC7.netTotalCost)
    }
  })], 1) : _vm._e(), _vm.accountTypes.includes('pv') ? _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": (_vm$analytics$pvCons = _vm.analytics['pv-consumption']) === null || _vm$analytics$pvCons === void 0 ? void 0 : (_vm$analytics$pvCons$ = _vm$analytics$pvCons.results) === null || _vm$analytics$pvCons$ === void 0 ? void 0 : _vm$analytics$pvCons$.netTotalCost,
      "prev-value": (_vm$analytics$pvCons2 = _vm.analytics['pv-consumption-prev']) === null || _vm$analytics$pvCons2 === void 0 ? void 0 : (_vm$analytics$pvCons3 = _vm$analytics$pvCons2.results) === null || _vm$analytics$pvCons3 === void 0 ? void 0 : _vm$analytics$pvCons3.netTotalCost,
      "precision": "2",
      "icon-class": "fa-solar-panel",
      "description": "PV",
      "units": "GBP",
      "prefix": "£ ",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['pv-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['pv-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['pv-consumption'],
      "estimated-percentage": ((_vm$analytics$pvCons4 = _vm.analytics['pv-consumption']) === null || _vm$analytics$pvCons4 === void 0 ? void 0 : (_vm$analytics$pvCons5 = _vm$analytics$pvCons4.results) === null || _vm$analytics$pvCons5 === void 0 ? void 0 : _vm$analytics$pvCons5.estimatedNetTotalCost) * 100 / ((_vm$analytics$pvCons6 = _vm.analytics['pv-consumption']) === null || _vm$analytics$pvCons6 === void 0 ? void 0 : (_vm$analytics$pvCons7 = _vm$analytics$pvCons6.results) === null || _vm$analytics$pvCons7 === void 0 ? void 0 : _vm$analytics$pvCons7.netTotalCost)
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": _vm.total.netTotalCost,
      "prev-value": _vm.total.prevNetTotalCost,
      "precision": "2",
      "icon-class": "fa-sum",
      "description": "Total",
      "units": "GBP",
      "prefix": "£ ",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['electricity-consumption'],
      "prev-value-loading": _vm.loadingAction.getAnalytics['electricity-consumption-prev'],
      "error": _vm.errorAction.getAnalytics['electricity-consumption'],
      "estimated-percentage": _vm.total.estimatedNetTotalCost / _vm.total.netTotalCost
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-12"
  }, [_c('Block', [_c('ChartCard', {
    attrs: {
      "title": "Invoice Consumption",
      "loading": _vm.loadingAction.getAnalytics["".concat(_vm.options.costGraph.accountType, "-consumption")],
      "error": _vm.errorAction.getAnalytics["".concat(_vm.options.costGraph.accountType, "-consumption")]
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('div', {
          staticClass: "row align-items-center justify-content-end"
        }, [_c('div', {
          staticClass: "col-md-8"
        }, [_c('FormItem', {
          staticClass: "mb-0",
          attrs: {
            "id": "consumptionType",
            "label": "Type",
            "type": "radio",
            "is-alt": true,
            "size": "sm",
            "options": [{
              label: 'vs Prev Period',
              value: 'default'
            }, {
              label: 'Estimated Data',
              value: 'estimated'
            }, {
              label: 'Landlord/Tenant',
              value: 'meterUser'
            }]
          },
          model: {
            value: _vm.options.costGraph.type,
            callback: function callback($$v) {
              _vm.$set(_vm.options.costGraph, "type", $$v);
            },
            expression: "options.costGraph.type"
          }
        })], 1), _c('div', {
          staticClass: "col-md-4"
        }, [_c('FormItem', {
          staticClass: "mb-0",
          attrs: {
            "id": "consumptioAccountType",
            "label": "Type",
            "type": "select",
            "is-alt": true,
            "size": "sm",
            "options": _vm.utilityTypes.map(function (type) {
              return {
                label: _vm.capitalize(type),
                value: type
              };
            }).concat({
              label: 'All',
              value: ''
            })
          },
          model: {
            value: _vm.options.costGraph.accountType,
            callback: function callback($$v) {
              _vm.$set(_vm.options.costGraph, "accountType", $$v);
            },
            expression: "options.costGraph.accountType"
          }
        })], 1)])];
      },
      proxy: true
    }], null, false, 3787063490)
  }, [[_c('apexchart', {
    attrs: {
      "height": "350",
      "options": _vm.consumptionChart.options,
      "series": _vm.consumptionChart.series
    }
  })]], 2)], 1)], 1)])], 1) : !_vm.analyticsOptions.selectedId ? _c('div', [_c('div', {
    staticClass: "mt-5 text-center alert alert-warning"
  }, [_vm._v("Select an asset/site, portfolio or fleet above to view analytics.")])]) : _c('div', [_vm._m(0)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-5 text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]), _c('div', {
    staticClass: "font-w600"
  }, [_vm._v("Loading...")])]);
}]

export { render, staticRenderFns }